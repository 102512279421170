import React, { useEffect, useState } from "react"
import DataTable from 'react-data-table-component';
const ProfitRightTable = ({ tableHeader, data, tableTitle}) => {
    const [columns, setTableHeader] = useState([]);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        setTableHeader(tableHeader);
        setRows(rows);
    })
    return (


        <div className="row">
            <div className="col-md-12 col-lg-12 col-xl-12">
                <div className="mb-3 card">
                    <div className="card-header-tab card-header">
                        <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                            <i className="header-icon lnr-dice mr-3 text-muted opacity-6"></i>
                            {tableTitle}
                        </div>
                        <div className="btn-actions-pane-right actions-icon-btn">
                            <div className="btn-group dropdown">
                                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="btn-icon btn-icon-only btn btn-link">
                                    <i className="pe-7s-menu btn-icon-wrapper"></i>
                                </button>
                                <div tabIndex="-1" role="menu" aria-hidden="true" className="dropdown-menu-shadow dropdown-menu-hover-link dropdown-menu" style={{ "position": "absolute transform: translate3d(0px, 35px, 0px) top: 0px left: 0px will-change: transform" }}>
                                    <h6 tabIndex="-1" className="dropdown-header">Actions</h6>

                                    <button type="button" tabIndex="0" className="dropdown-item">
                                        <i className="dropdown-icon lnr-book"></i>
                                        <span>Add Sales</span>
                                    </button>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div >
                        <DataTable

                            columns={columns}
                            data={data}


                        />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ProfitRightTable