import React, { useEffect } from 'react';
import LineChart from '../common/Charts/LineChart';
const DashBoard = () => {

    return (

        <div className="">
            <div className="app-main__inner">
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div className="page-title-icon">
                                <i className="pe-7s-graph icon-gradient bg-ripe-malin"></i>
                            </div>
                            <div>
                                Sales/Expense Dashboard
                                <div className="page-title-subheading"></div>
                            </div>
                        </div>
                        <div className="page-title-actions">
                          

                        </div>
                    </div>
                </div>
                <div className="tabs-animation">
                            <div className="row">
                                <div className="col-lg-12 col-xl-6">
                                    <div className="main-card mb-3 card">
                                        <div className="card-body">
                                            <h5 className="card-title">Income Report</h5>
                                            <div className="widget-chart-wrapper widget-chart-wrapper-lg opacity-10 m-0">
                                                <div style={{"height": "260px"}}><div className="chartjs-size-monitor"><div className="chartjs-size-monitor-expand"><div className=""></div></div><div className="chartjs-size-monitor-shrink"><div className=""></div></div></div>
                                                <LineChart></LineChart>
                                                </div>
                                            </div>
                                            <h5 className="card-title">Target Sales</h5>
                                            <div className="mt-3 row">
                                                <div className="col-sm-12 col-md-4">
                                                    <div className="widget-content p-0">
                                                        <div className="widget-content-outer">
                                                            <div className="widget-content-wrapper">
                                                                <div className="widget-content-left">
                                                                    <div className="widget-numbers text-dark">65%</div>
                                                                </div>
                                                            </div>
                                                            <div className="widget-progress-wrapper mt-1">
                                                                <div className="progress-bar-xs progress-bar-animated-alt progress">
                                                                    <div className="progress-bar bg-info" role="progressbar" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100" style={{width: "65%"}}>
                                                                    </div>
                                                                </div>
                                                                <div className="progress-sub-label">
                                                                    <div className="sub-label-left font-size-md">Sales</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-4">
                                                    <div className="widget-content p-0">
                                                        <div className="widget-content-outer">
                                                            <div className="widget-content-wrapper">
                                                                <div className="widget-content-left">
                                                                    <div className="widget-numbers text-dark">22%</div>
                                                                </div>
                                                            </div>
                                                            <div className="widget-progress-wrapper mt-1">
                                                                <div className="progress-bar-xs progress-bar-animated-alt progress">
                                                                    <div className="progress-bar bg-warning" role="progressbar" aria-valuenow="22" aria-valuemin="0" aria-valuemax="100" style={{width: "22%"}}>
                                                                    </div>
                                                                </div>
                                                                <div className="progress-sub-label">
                                                                    <div className="sub-label-left font-size-md">Profiles</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-4">
                                                    <div className="widget-content p-0">
                                                        <div className="widget-content-outer">
                                                            <div className="widget-content-wrapper">
                                                                <div className="widget-content-left">
                                                                    <div className="widget-numbers text-dark">83%</div>
                                                                </div>
                                                            </div>
                                                            <div className="widget-progress-wrapper mt-1">
                                                                <div className="progress-bar-xs progress-bar-animated-alt progress">
                                                                    <div className="progress-bar bg-success" role="progressbar" aria-valuenow="83" aria-valuemin="0" aria-valuemax="100" style={{width: "83%"}}>
                                                                    </div>
                                                                </div>
                                                                <div className="progress-sub-label">
                                                                    <div className="sub-label-left font-size-md">Tickets</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-xl-6">
                                    <div className="main-card mb-3 card">
                                        <div className="grid-menu grid-menu-2col">
                                            <div className="no-gutters row">
                                                <div className="col-sm-6">
                                                    <div className="widget-chart widget-chart-hover">
                                                        <div className="icon-wrapper rounded-circle">
                                                            <div className="icon-wrapper-bg bg-primary"></div>
                                                            <i className="lnr-cog text-primary"></i>
                                                        </div>
                                                        <div className="widget-numbers">45.8k</div>
                                                        <div className="widget-subheading">Total Views</div>
                                                        <div className="widget-description text-success">
                                                            <i className="fa fa-angle-up"></i>
                                                            <span className="pl-1">175.5%</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="widget-chart widget-chart-hover">
                                                        <div className="icon-wrapper rounded-circle">
                                                            <div className="icon-wrapper-bg bg-info"></div>
                                                            <i className="lnr-graduation-hat text-info"></i>
                                                        </div>
                                                        <div className="widget-numbers">63.2k</div>
                                                        <div className="widget-subheading">Bugs Fixed</div>
                                                        <div className="widget-description text-info">
                                                            <i className="fa fa-arrow-right"></i>
                                                            <span className="pl-1">175.5%</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="widget-chart widget-chart-hover">
                                                        <div className="icon-wrapper rounded-circle">
                                                            <div className="icon-wrapper-bg bg-danger"></div>
                                                            <i className="lnr-laptop-phone text-danger"></i>
                                                        </div>
                                                        <div className="widget-numbers">5.82k</div>
                                                        <div className="widget-subheading">Reports Submitted</div>
                                                        <div className="widget-description text-primary">
                                                            <span className="pr-1">54.1%</span>
                                                            <i className="fa fa-angle-up"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="widget-chart widget-chart-hover br-br">
                                                        <div className="icon-wrapper rounded-circle">
                                                            <div className="icon-wrapper-bg bg-success"></div>
                                                            <i className="lnr-screen"></i>
                                                        </div>
                                                        <div className="widget-numbers">17.2k</div>
                                                        <div className="widget-subheading">Profiles</div>
                                                        <div className="widget-description text-warning">
                                                            <span className="pr-1">175.5%</span>
                                                            <i className="fa fa-arrow-left"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          
       
                          
                        </div>
            </div>
        </div>
    );
}

export default DashBoard;