import React from 'react';
import Header from './Header';
import Navigation from './Navigation';
import Footer from './Footer'
const Layout=({children})=> {
    
    return (
        <div className="app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar">
            <Header></Header>
            <div className="app-main">
                <Navigation />
                <div className="app-main__outer">
                    <div className="app-main__inner">
                        {children}
                    </div>
                    <Footer/>
                </div>
            </div>
        </div>
    );
}

export default Layout;