import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import {company} from './company.reducer';
import {product} from './product.reducer'
const rootReducer = combineReducers({
    authentication,
    registration,
    users,
    alert,
    company,product
});

export default rootReducer;