import React from "react";
const Card = ({ cardinfo, children }) => {
    return (
        <div className={`card ${cardinfo.cardSize}`}>
            <div className="card-header-tab card-header">
                <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                    <i className="header-icon lnr-shirt mr-3 text-muted opacity-6"></i>
                    {cardinfo.title}
                </div>
            </div>
            <div className="widget-chart widget-chart2 text-left p-0">
                <div className="widget-chat-wrapper-outer">
                    <div className="widget-chart-content widget-chart-content-lg">
                        <div className="widget-chart-flex">
                            <div className="widget-title opacity-5 text-muted text-uppercase">{cardinfo.shortInfo}</div>
                        </div>
                        <div className="widget-numbers">
                            <div className="widget-chart-flex">
                                <div>
                                    <span className={`opacity-10 ${cardinfo.topSelling?"text-success":"text-warning"} pr-2`}>
                                        <i className={`fa ${cardinfo.indicator}`}></i>
                                    </span>
                                    <span>{cardinfo.value}</span>
                                    <small className="opacity-5 pl-1">{cardinfo.percentage}</small>
                                </div>
                                <div className="widget-title ml-2 font-size-lg font-weight-normal text-muted">
                                    <span className={`${cardinfo.topSelling?"text-danger":"text-success"} pl-2`}>{cardinfo.value2}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {children}
            <div className="d-block text-center rm-border card-footer">
                <button className="btn btn-primary">
                    {cardinfo.btnName}
                    <span className="text-white pl-2 opacity-3">
                        <i className="fa fa-arrow-right"></i>
                    </span>
                </button>
            </div>
        </div>
    )
}

export default Card;