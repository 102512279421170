
let productList = JSON.parse(localStorage.getItem('products')) ? JSON.parse(localStorage.getItem('products')) : [];
const initialState = productList;

export function product(state = initialState, action) {
    const product = action.product;
    switch (action.type) {
        case "AddProduct":
            return {
                ...state,
                addingProduct: true
            };
        case "RemoveProduct":
            return {
                ...state
            };
        case "GetProducts":
            return {
                ...state,
                ...product
            };
        case "Success":
            let result=[...state,product]
            return result;
            
        case "Error":
            return {
                productAdded: false
            }
        default:
            return state
    }
}