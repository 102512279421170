import React, { useEffect } from 'react';
import Card from '../common/Card';
import Sparklines from '../common/Charts/SparklineChart';
import ProfitRightTable from '../common/ProgitRightTable';
const Expenses = () => {

    const cardInfo = {
        title: "Top Expenses",
        shortInfo: "New Accounts Since 2018",
        percentage: "%",
        value: "9%",
        value2: "+14% failed",
        indicator: "fa-angle-up",
        cardSize: "mb-3",
        btnName: "View complete report",
        topSelling: true
    }
    const cardInfoPopularSale = {
        title: "Highesh Expensed Product",
        shortInfo: "Prduct XYZ ",
        percantage: "",
        value: "$984",
        value2: "+14",
        indicator: "fa-dot-circle",
        cardSize: "mb-3",
        btnName: "View All",
        topSelling: false
    }

    const tableHeader = [
        { name: "Name", selector: row => row.name },
        { name: "Total Sold", selector: row => row.totalsold },
        { name: "Price", selector: row => row.costprice },
    ]
    const data = [
        { id: 1, name: "product1", totalsold: "50", costprice: "5" },
        { id: 2, name: "product2", totalsold: "60", costprice: "10" },
        { id: 3, name: "product3", totalsold: "70", costprice: "15" },
        { id: 4, name: "product4", totalsold: "50", costprice: "25" },
        { id: 5, name: "product5", totalsold: "50", costprice: "35" },
        { id: 6, name: "product6", totalsold: "50", costprice: "45" },
        { id: 7, name: "product7", totalsold: "50", costprice: "55" },
        { id: 8, name: "product8", totalsold: "50", costprice: "65" },
        { id: 9, name: "product9", totalsold: "50", costprice: "75" },
        { id: 10, name: "product10", totalsold: "50", costprice: "85" },

    ];
    return (
        <>
            <div className="row">
                <div className="col-lg-6 col-xl-6">
                    <Card cardinfo={cardInfo}>
                        <Sparklines colors={["#72e578"]}></Sparklines>
                    </Card>

                </div>

                <div className="col-lg-6 col-xl-6">
                    <Card cardinfo={cardInfoPopularSale}>
                        <Sparklines colors={["#eaaf25"]}></Sparklines>
                    </Card>

                </div>
            </div>

            <ProfitRightTable tableHeader={tableHeader} data={data} tableTitle="Expenses"></ProfitRightTable>
        </>
    );
}

export default Expenses;