
let userCompany = JSON.parse(localStorage.getItem('companies')) ? JSON.parse(localStorage.getItem('companies'))[0] : {};
const initialState = userCompany.userId ? { ...userCompany, registered: true } : {
   registered: false
};

export function company(state = initialState, action) {
    const company = action.company;
    switch (action.type) {

        case "Register":
            return {
                ...state,
                registering: true,
                company
            };
        case "RegisterSucess":

            return {
                ...state,
                registered: true,
                companyName: company.companyName,
                userId: company.userId
            };
        case "GetCompany":

            return {
                ...state,
                company
            };
        case "GetCompanyByUser":
            return {
                ...state
            }
        case "Sucess":

            return {
                ...state,
                registered: true,
                companyName: company.companyName,
                userId: company.userId
            };
        default:
            return state
    }
}