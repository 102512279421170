import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { productActions } from '../_actions/product.actions';
const ProductSetting = () => {
  //validation using YUP later
  const defaultInputs = {
    productName: '',
    productType: ''
  };
  const [inputs, setInputs] = useState(defaultInputs);
  const { productName, productType } = inputs;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => { setInputs(inputs); setShow(true) };
  const products = useSelector(state => state.product);
  const [data,setData]=useState([]);
  const dispatch = useDispatch();
  function handleChange(e) {
    const { name, value } = e.target;
    setInputs(inputs => ({ ...inputs, [name]: value }));
  }

  function handleSave(e) {
    e.preventDefault();
    console.log(productName, productType);
    dispatch(productActions.addProduct({productName,productType}));
    setShow(false);
  }

  useEffect(() => {
    setData(products)
  }, [products]);


  const tableHeader = [
    { name: "Product Name", selector: row => row.productName },
    { name: "Product Type", selector: row => row.productType },
  ]



  return (
    <>
      <button type="button" onClick={handleShow} className="btn btn-primary mb-3">
        Add Products
      </button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <form className="" name="form">
            <div className="form-row">
              <div className="col-md-12">
                <div className="position-relative form-group">
                  <input name="productName" id=""
                    placeholder="product name here..." type="text" value={productName} onChange={handleChange} className={'form-control'} />

                </div>
              </div>
              <div className="col-md-12">
                <div className="position-relative form-group">
                  <input name="productType" id=""
                    placeholder="product type here..." type="text" value={productType} onChange={handleChange} className={'form-control'} />

                </div>
              </div>
            </div>
          </form>

        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-primary" onClick={handleClose}>
            Close
          </button>
          <button type="button" className="btn btn-secondary" onClick={handleSave}>
            Save
          </button>
        </Modal.Footer>
      </Modal>
      <div className="row">
        <div className="col-md-12 col-lg-12 col-xl-12">
          <div className="mb-3 card">
            <div className="card-header-tab card-header">
              <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                <i className="header-icon lnr-dice mr-3 text-muted opacity-6"></i>
                Product
              </div>
            </div>
            <div >
              <DataTable

                columns={tableHeader}
                data={data}


              />
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

export default ProductSetting;