
import { productService } from '../_services';
import { alertActions } from '.';
import { history } from '../_helpers';

export const productActions = {
    getAll, getProductById,addProduct
};

function addProduct(product) {
    return dispatch => {
        productService.AddProduct(product)
            .then(

                product => {
                    dispatch({ type: 'Success', product })
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
}


function getAll() {
    return dispatch => {
        productService.getAll()
            .then(
                products => dispatch({ type: 'GetProducts', products }),
                error => dispatch(alertActions.error(error.toString()))
            );
    };

}

function getProductById(id) {
    return dispatch => {
        productService.getProductById(id)
            .then(
                product => dispatch({ type: 'Sucess', product }),
                error => dispatch(alertActions.error(error.toString()))
            );
    };
}

