import React from "react";
import Chart from "react-apexcharts";

const Sparklines = (props) => {
    const randomScalingFactor = function () {
        return Math.round(Math.random() * 100);
    };
    const sparkSettings = {

        series: [{
            name: 'series1',
            //data: [31, 40, 28, 51, 42, 109, 100]
            data: [randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),]
        }],
        options: {
            chart: {
                height: 350,
                type: 'area',
                toolbar: {
                    show: false
                },

            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            colors: props.colors,
            yaxis: {
                show: false
            },
            xaxis: {
                labels: {
                    show: false
                }
            },
            grid:{show:false}

        },


    };

    return (
        <Chart
            options={sparkSettings.options}
            series={sparkSettings.series}
          type="area"

        />
    )
}






export default Sparklines;