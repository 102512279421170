
import { companyService } from '../_services';
import { alertActions } from '.';
import { history } from '../_helpers';

export const companyActions = {
    register,
    getAll, getCompanyByUser
};

function register(company) {
    return dispatch => {
        companyService.register(company)
            .then(

                company => {
                    history.push('/home');
                    //dispatch(alertActions.success('Registration successful'));
                    dispatch({ type: 'RegisterSucess', company })
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
}


function getAll() {
    return dispatch => {
        companyService.getAll()
            .then(
                company => dispatch({ type: 'GetCompany', company }),
                error => dispatch(alertActions.error(error.toString()))
            );
    };

}

function getCompanyByUser(userId) {
    return dispatch => {

        companyService.getCompanyByUser(userId)
            .then(
                company => dispatch({ type: 'Sucess', company }),
                error => dispatch(alertActions.error(error.toString()))
            );
    };
}

