import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../_actions';

function LoginPage() {
    const [inputs, setInputs] = useState({
        username: '',
        password: ''
    });
    const [submitted, setSubmitted] = useState(false);
    const { username, password } = inputs;
    const loggingIn = useSelector(state => state.authentication.loggingIn);
    const dispatch = useDispatch();
    const location = useLocation();

    // reset login status
    useEffect(() => {
        dispatch(userActions.logout());      
    }, []);

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    function handleSubmit(e) {
        e.preventDefault();

        setSubmitted(true);
        if (username && password) {

            const { from } = location.state || { from: { pathname: "/" } };
            dispatch(userActions.login(username, password, from));

            //dispatch(companyActions.getCompanyByUser,user.id)
        }
    }

    return (

        <div className="app-container app-theme-white body-tabs-shadow">
            <div className="app-container">
                <div className="h-100 bg-plum-plate bg-animation">
                    <div className="d-flex h-100 justify-content-center align-items-center">
                        <div className="mx-auto app-login-box col-md-8">
      
                            <div className="modal-dialog w-100 mx-auto">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <div className="h5 modal-title text-center">
                                            <h4 className="mt-2">
                                                <div>Welcome back,</div>
                                                <span>Please sign in to your account below.</span>
                                            </h4>
                                        </div>
                                        <form className="" name="form" onSubmit={handleSubmit}>
                                            <div className="form-row">
                                                <div className="col-md-12">
                                                    <div className="position-relative form-group">
                                                        <input name="username" id=""
                                                            placeholder="username here..." type="text" value={username} onChange={handleChange} className={'form-control' + (submitted && !username ? ' is-invalid' : '')} />
                                                        {submitted && !username &&
                                                            <div className="invalid-feedback">Username is required</div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="position-relative form-group">
                                                        <input name="password" id=""
                                                            placeholder="Password here..." type="password" value={password} onChange={handleChange} className={'form-control' + (submitted && !password ? ' is-invalid' : '')} />
                                                        {submitted && !password &&
                                                            <div className="invalid-feedback">Password is required</div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="position-relative form-check">
                                                <input name="check" id="" type="checkbox" className="form-check-input" />
                                                <label htmlFor="exampleCheck" className="form-check-label">Keep me logged in</label>
                                            </div>
                                            <div className="modal-footer clearfix">
                                                <div className="float-left">
                                                    <a href="/login" className="btn-lg btn btn-link">Recover Password</a>
                                                </div>
                                                <div className="float-right">

                                                    <button className="btn btn-primary btn-lg">
                                                        {loggingIn && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                                        Login
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                        <div className="divider"></div>
                                        <h6 className="mb-0">No account?
                                            <Link to="/register" className="text-primary">Register</Link>
                                        </h6>
                                    </div>

                                </div>
                            </div>
                            <div className="text-center text-white opacity-8 mt-3">Copyright © ProfitRight</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { LoginPage };