// Chart.js
import React from 'react';
import { Line } from 'react-chartjs-2';
 
  const LineChart=()=>{
   const chartColors = {
        red: "#dc3545",
        orange: "#fd7e14",
        yellow: "#ffc107",
        green: "#28a745",
        blue: "#007bff",
        purple: "#6f42c1",
        grey: "#6c757d",
      };
      
    const randomScalingFactor = function () {
        return Math.round(Math.random() * 100);
      };
      
    
      const configLine = {
        type: "line",
        data: {
          labels: ["January", "February", "March", "April", "May", "June", "July"],
          datasets: [
            {
              label: "My First dataset",
              backgroundColor: chartColors.red,
              borderColor: chartColors.red,
              data: [
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
              ],
              fill: false,
            },
            {
              label: "My Second dataset",
              fill: false,
              backgroundColor: chartColors.blue,
              borderColor: chartColors.blue,
              data: [
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
              ],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: false,
            text: "Chart.js Line Chart",
          },
          legend: {
            display: false,
          },
          layout: {
            padding: {
              left: 10,
              right: 10,
              top: 10,
              bottom: 0,
            },
          },
          tooltips: {
            mode: "index",
            intersect: false,
          },
          hover: {
            mode: "nearest",
            intersect: true,
          },
          pointBackgroundColor: "#fff",
          pointBorderColor: chartColors.blue,
          pointBorderWidth: "2",
          scales: {
            xAxes: [
              {
                display: false,
                scaleLabel: {
                  display: true,
                  labelString: "Month",
                },
              },
              
            ],
            yAxes: [
              {
                display: false,
                scaleLabel: {
                  display: true,
                  labelString: "Value",
                },
              },
            ],
          },
        },
      };

      return (
        <Line data={configLine.data} options={configLine.options}  />
      )
  }
  
  export default LineChart