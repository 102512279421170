import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { companyActions } from '../_actions/company.actions';

const CompanyRegistration = () => {
    const company = useSelector(state => state.company);
    const { register, handleSubmit } = useForm(({
        defaultValues: {
        }
    }));

    const history = useHistory();
    const dispatch = useDispatch();
    const onSubmit = (data) => {
        dispatch(companyActions.register(data))
    }

    useEffect(() => {
          if (company.registered) { 
            let path = '/'; 
            history.push(path);
         }
    }, [company.registered]);


    return (
        <div className="h-100 no-gutters row mt-4">
            <div className="h-100 d-md-flex d-sm-block bg-white justify-content-center align-items-center">
                <div className="mx-auto app-login-box col-sm-12 col-md-10 col-lg-9">

                    <h4>
                        <div>Register Company,</div>
                    </h4>
                    <div>
                        <form className="" onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-row">
                                <div className="col-md-6">
                                    <div className="position-relative form-group">
                                        <label htmlFor="companyName" className="">
                                            <span className="text-danger">*</span> Company Name
                                        </label>
                                        <input name="companyName" id="companyName" placeholder="Name here..." type="text"
                                            {...register("companyName", { required: true, maxLength: 20 })}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="position-relative form-group">
                                        <label htmlFor="email" className="">Email</label>
                                        <input name="email" id="email" placeholder="Email here..." type="email"
                                            {...register("email", { required: true, pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ })}
                                            className="form-control" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="position-relative form-group">
                                        <label htmlFor="description" className="">
                                            <span className="text-danger">*</span> Description
                                        </label>
                                        <input name="description" id="description" placeholder="Description here..."
                                            {...register("description", { required: true })}
                                            type="text" className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4 d-flex align-items-center">
                                <div className="mr-auto">
                                    <input type="submit" value="Register" className="btn-wide btn-pill btn-shadow btn-hover-shine btn btn-primary btn-lg" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div></div>
    );
}

export default CompanyRegistration;