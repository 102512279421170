import config from '../config.json'
import { authHeader } from '../_helpers';

export const companyService = {
    register,getAll,getCompanyByUser
};


function register(company) {
    const requestOptions = {
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(company)
    };

    return fetch(`${config.apiUrl}/company/register`, requestOptions).then(handleResponse);
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        data.registered=true;
        return data;
    });
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/companies`, requestOptions).then(handleResponse);
}

function getCompanyByUser(userId){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/companies/${userId}`, requestOptions).then(handleResponse);
}