import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DashBoard from '../DashBoardPage/DashBoard';
function HomePage() {
    const user = useSelector(state => state.authentication.user);
    useEffect(() => {

    }, []);

    return (

        <DashBoard></DashBoard>

    );
}

export { HomePage };