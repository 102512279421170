import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Layout from '../Layout/Layout';
import { useSelector } from 'react-redux';
import CompanyRegistration from '../CompanyPage/RegisterCompany';
function PrivateRoute({ component: Component, roles, ...rest }) {
    const auth = useSelector(state => state.authentication);
    const company=useSelector(state => state.company);

    useEffect(() => {
      
    }, []);

    return (
        <Route {...rest} render={props => {
            if (!auth.loggedIn) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            }
            if(!company.registered){
                return (
                    <Layout>
                    <CompanyRegistration {...props}></CompanyRegistration>
                </Layout>
                )
            }
            
            return (
            <Layout>
                <Component {...props} />
            </Layout>
            )
        }} />
    );
}

export { PrivateRoute };