import React from 'react';
import { Link } from 'react-router-dom';
const Navigation = () => {
    return (

        <div className="app-sidebar sidebar-shadow">
            <div className="app-header__logo">
                <div className="logo-src"></div>
                <div className="header__pane ml-auto">
                    <div>
                        <button type="button" className="hamburger close-sidebar-btn hamburger--elastic" data-class="closed-sidebar">
                            <span className="hamburger-box">
                                <span className="hamburger-inner"></span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="app-header__mobile-menu">
                <div>
                    <button type="button" className="hamburger hamburger--elastic mobile-toggle-nav">
                        <span className="hamburger-box">
                            <span className="hamburger-inner"></span>
                        </span>
                    </button>
                </div>
            </div>
            <div className="app-header__menu">
                <span>
                    <button type="button" className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                        <span className="btn-icon-wrapper">
                            <i className="fa fa-ellipsis-v fa-w-6"></i>
                        </span>
                    </button>
                </span>
            </div>
            <div className="scrollbar-sidebar ps ps--active-y">
                <div className="app-sidebar__inner">
                    <ul className="vertical-nav-menu metismenu">
                        <li className="app-sidebar__heading">Menu</li>
                        <li className="mm-active">
                            <a href="widgets-chart-boxes.html">
                                <i className="metismenu-icon pe-7s-graph"></i>
                                Dashboard
                            </a>
                        </li>
                        <li>
                            <Link to="/sales" className="nav-link">
                                <i className="metismenu-icon pe-7s-way"></i>
                                Sales</Link>
                        </li>
                        <li>

                            <Link to="/expenses" className="nav-link">
                                <i className="metismenu-icon pe-7s-way"></i>
                                Expenses</Link>
                        </li>
                        <li>
                        <a href="#" aria-expanded="false">
                                        <i className="metismenu-icon pe-7s-plugin"></i>
                                        Settings
                                        <i className="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                                    </a>
                            <ul className="mm-collapse" style={{"height":"7.04px"}}>
                                <li>
                                    <Link to="/products" className="nav-link">Products Settings</Link>
                                </li>
                                <li>
                                    <Link to="/settings" className="nav-link">Company Settings</Link>
                                </li>
                                <li>
                                    <Link to="/settings" className="nav-link">Profile Settings</Link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <Link to="/login" className="nav-link">Logout</Link>
                        </li>
                    </ul>
                </div>
                <div className="ps__rail-x"><div className="ps__thumb-x" tabIndex="0" ></div></div><div className="ps__rail-y" ><div className="ps__thumb-y" tabIndex="0" ></div></div></div>
        </div>
    );
}

export default Navigation;