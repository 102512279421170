import React, { useEffect } from 'react';

const Settings = () => {

    return (

      <>Settings</>
    );
}

export default Settings;