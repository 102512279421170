import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
const Header = () => {
  const user = useSelector(state => state.authentication.user);
  return (

    <div className="app-header header-shadow">
                <div className="app-header__logo">
                    <div className="">Your Logo</div>
                    <div className="header__pane ml-auto">
                        <div>
                            <button type="button" className="hamburger close-sidebar-btn hamburger--elastic" data-class="closed-sidebar">
                                <span className="hamburger-box">
                                    <span className="hamburger-inner"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="app-header__mobile-menu">
                    <div>
                        <button type="button" className="hamburger hamburger--elastic mobile-toggle-nav">
                            <span className="hamburger-box">
                                <span className="hamburger-inner"></span>
                            </span>
                        </button>
                    </div>
                </div>
                <div className="app-header__menu">
                    <span>
                        <button type="button" className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                            <span className="btn-icon-wrapper">
                                <i className="fa fa-ellipsis-v fa-w-6"></i>
                            </span>
                        </button>
                    </span>
                </div>
                <div className="app-header__content">
                    <div className="app-header-left">
                        <div className="search-wrapper">
                            <div className="input-holder">
                                <input type="text" className="search-input" placeholder="Type to search" />
                                <button className="search-icon">
                                    <span></span>
                                </button>
                            </div>
                            <button className="close"></button>
                        </div>
                    </div>
                    <div className="app-header-right">
                      <div className="header-btn-lg pr-0">
                            <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                    <div className="widget-content-left">
                                        <div className="btn-group">
                                            <a href="/#" data-toggle="dropdown"  aria-expanded="false" className="p-0 btn">
                                                <img className="rounded-circle" src="images/avatars/1.jpg" alt="" width="42" />
                                                <i className="fa fa-angle-down ml-2 opacity-8"></i>
                                            </a>
                                            <div tabIndex="-1" role="menu" aria-hidden="true" className="rm-pointers dropdown-menu-lg dropdown-menu dropdown-menu-right">
                                                <div className="dropdown-menu-header">
                                                    <div className="dropdown-menu-header-inner bg-info">
                                                        <div className="menu-header-image opacity-2" ></div>
                                                        <div className="menu-header-content text-left">
                                                            <div className="widget-content p-0">
                                                                <div className="widget-content-wrapper">
                                                                    <div className="widget-content-left mr-3">
                                                                        <img className="rounded-circle" src="images/avatars/1.jpg" alt="" width="42" />
                                                                    </div>
                                                                    <div className="widget-content-left">
                                                                        <div className="widget-heading">{user.firstName} {user.lastName}</div>
                                                                        {/* <div className="widget-subheading opacity-8">A short profile description</div> */}
                                                                    </div>
                                                                    {/* <div className="widget-content-right mr-2">
                                                                      <button className="btn-pill btn-shadow btn-shine btn btn-focus">Logout</button>
                                                                        
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="scroll-area-xs" >
                                                    <div className="scrollbar-container ps">
                                                        <ul className="nav flex-column">
                                                            <li className="nav-item-header nav-item">
                                                                My Account
                                                            </li>
                                                            <li className="nav-item">
                                                                <a href="/#" className="nav-link">Recover Password</a>
                                                            </li>
                                                            <li className="nav-item">
                                                                <a href="/#" className="nav-link">
                                                                    Settings
                                                                </a>
                                                            </li>
                                                            <li className="nav-item">
                                                                <Link to="/login" className="nav-link">Logout</Link>
                                                            </li>
                                                        </ul>
                                                    <div className="ps__rail-x" ><div className="ps__thumb-x" tabIndex="0" ></div></div><div className="ps__rail-y" ><div className="ps__thumb-y" tabIndex="0" ></div></div></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="widget-content-left  ml-3 header-user-info">
                                        <div className="widget-heading"> {user.firstName} {user.lastName}</div>
                                        <div className="widget-subheading"> </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
  );
}

export default Header;