import React, { useEffect } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { history } from './_helpers';
import { alertActions } from './_actions';
import { PrivateRoute } from './_components';
import { HomePage } from './HomePage';
import { LoginPage } from './LoginPage';
import { RegisterPage } from './RegisterPage';
import Sales from './SalesPage/Sales';
import Expenses from './ExpensesPage/Expense';
import Settings from  './SettingsPage/Settings';
import ProductSetting from './SettingsPage/ProductSettings'
import CompanyRegistration from './CompanyPage/RegisterCompany';

const  App=()=> {
    const dispatch = useDispatch();
    useEffect(() => {
        history.listen((location, action) => {
            // clear alert on location change
            dispatch(alertActions.clear());
        });
    }, []);

    return (
        
                    <Router history={history}>
                        <Switch>                           
                            <PrivateRoute exact path="/" component={HomePage} />
                            <PrivateRoute exact path="/companyregister" component={CompanyRegistration} />
                            <PrivateRoute exact path="/sales" component={Sales} />
                            <PrivateRoute exact path="/expenses" component={Expenses} />
                            <PrivateRoute exact path="/settings" component={Settings} />
                            <PrivateRoute exact path="/products" component={ProductSetting} />
                            <Route path="/login" component={LoginPage} />
                            <Route path="/register" component={RegisterPage} />
                            <Redirect from="*" to="/" />
                        </Switch>
                    </Router>
        
    );
}

export default App ;