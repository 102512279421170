import React from 'react';

const Footer = () => {

  return (

                   <div className="app-wrapper-footer">
                        <div className="app-footer">
                            <div className="app-footer__inner">
                                <div className="app-footer-left">
                                    <span>Copyright © 2021 All Rights Reserved.</span>
                                </div>
                                <div className="app-footer-right">
                                    <span>ProfitRight</span>
                                </div>
                            </div>
                        </div>
                    </div>
  );
}

export default Footer;